<template>
  <div class="loadList">
    <van-list
      v-if="interFace.api"
      v-model="loading"
      :finished="finished"
      finished-text="我也是有底线得~~"
      @load="onLoad"
    >
      <slot name="list" :list="list" />
    </van-list>
  </div>
</template>
<script>
export default {
  props: {
    interFace: {
      type: Object,
      require: true,
    },
    isFreeze: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
      pagesize: 10,
      totalpage: 1,
      list: [],
      loading: false,
      finished: false,
    };
  },

  watch: {
    //监听传入数据变化
    interFace: {
      handler() {
        this.list = [];
        this.page = 1;
        // this.totalpage =1;
        this.finished = false;
        this.get_list();
      },
      deep: true, //深度监听
    },
  },
  methods: {
    async onLoad() {
      if (this.page <= this.totalpage) {
        this.loading = true;
        await this.get_list();
        this.loading = false;
      } 
        else {
        this.loading = false;
        this.finished = true;
      }
    },
    async get_list() {
      // this.$toast.loading({
      //   message:'加载中',
      //   forbidClick:true,
      //   duration:30000
      // })
      const res = await this.interFace["api"]({
        page: this.page,
        pagesize: 10,
        ...this.interFace.params,
      })
      // this.$toast.clear();
      this.list = this.isFreeze
        ? Object.freeze([...this.list, ...(res.data.items || res.data.data || res.data.options)])
        : [...this.list, ...(res.data.items || res.data.data)];
      this.totalpage = res.data.totalpage || res.data.last_page;
      this.page++;
    },
  },
};
</script>

<style scoped lang="scss">
</style>